<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div>
          <h1 class="page-title">
            <a-icon type="arrow-left" @click="goToBack" />
            {{ title }}
          </h1>
        </div>
        <div>
          <a-breadcrumb class="page-link" separator=">">
            <a-breadcrumb-item href="">
              <a-icon type="home" @click="goToHome" />
            </a-breadcrumb-item>
            <a-breadcrumb-item href="">
              <span @click="goToBack">
                {{ $t('voucher.voucher_lists') }}
              </span>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              {{ title }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>
    </div>

    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="isDetail ? {} : rules"
      :class="{ info: isDetail }"
    >
      <!-- Rincian Dasar -->
      <a-card :title="$t('discount.basic_details')" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="20">
            <a-form-model-item :label="$t('voucher.voucher_name')" prop="promotion_name">
              <a-input
                v-model.trim="form.promotion_name"
                size="large"
                autocomplete="off"
                :placeholder="$t('voucher.voucher_name')"
                :disabled="isDetail"
                allow-clear
                @blur="CheckVoucherName(form.promotion_name)"
              />
              <a-alert
                v-if="isValid === false"
                class="mt-2"
                type="error"
                message="Voucher tersebut telah tersedia. Silakan cek voucher tersebut pada daftar promo untuk menghindari duplikat promo"
                banner
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="20">
            <a-form-model-item :label="$t('voucher.voucher_code')" prop="code">
              <a-input
                v-model.trim="form.code"
                size="large"
                autocomplete="off"
                :placeholder="$t('voucher.voucher_code')"
                :disabled="isDetail || !isValid"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <h6 class="mb-0" style="font-weight: bold">
              {{ $t('voucher.voucher_get') }}
            </h6>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('discount.start_date')" prop="start_date">
              <a-date-picker
                v-model="form.start_date"
                show-time
                size="large"
                class="w-100"
                :format="dateFormat"
                :placeholder="$t('discount.start_date')"
                :disabled-date="disabledStartDate"
                :disabled="isDetail || !isValid"
                @openChange="onStartOpenChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item :label="$t('discount.end_date')" prop="end_date">
              <a-date-picker
                v-model="form.end_date"
                show-time
                size="large"
                class="w-100"
                :format="dateFormat"
                :placeholder="$t('discount.end_date')"
                :open="endOpen"
                :disabled-date="disabledEndDate"
                :disabled="isDetail || !isValid"
                @openChange="onEndOpenChange"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <h6 style="font-weight: bold">
              {{ $t('voucher.voucher_usage') }}
            </h6>
            <a-checkbox v-if="!isDetail" @change="onChangePeriod">
              {{ $t('voucher.same_period') }}
            </a-checkbox>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('discount.start_date')" prop="start_until">
              <a-date-picker
                v-model="form.start_until"
                show-time
                size="large"
                class="w-100"
                :format="dateFormat"
                :placeholder="$t('discount.start_date')"
                :disabled-date="disabledStartUntil"
                :disabled="true"
                @openChange="onStartChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item :label="$t('discount.end_date')" prop="end_until">
              <a-date-picker
                v-model="form.end_until"
                show-time
                size="large"
                class="w-100"
                :format="dateFormat"
                :placeholder="$t('discount.end_date')"
                :open="untilOpen"
                :disabled-date="disabledEndUntil"
                :disabled="isDetail || !isValid"
                @openChange="onEndChange"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>

      <!-- Target Promosi -->
      <a-card :title="$t('discount.promotion_target')" class="w-100 mt-4" :bordered="false">
        <div v-if="isPriciple && form.seller_base === 2">
          <div class="radio-title">
            Penjual
          </div>
          <div class="radio-subtitle mb-3">
            Tentukan penjual berdasarkan area dan penjual
          </div>
          <div class="target-promotion">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item label="Area" prop="area" class="mb-2">
                  <a-select
                    v-model="form.area"
                    mode="multiple"
                    size="large"
                    class="select-antd-default"
                    option-filter-prop="children"
                    placeholder="Cari & Pilih Area"
                    :filter-option="filterOption"
                    :options="areas"
                    :max-tag-count="5"
                    :disabled="areaAll || isDetail"
                    show-search
                    allow-clear
                    @change="onChangeSelect('areas')"
                  />
                  <a-checkbox v-if="!isDetail" name="area" @change="onChangeAll">
                    Pilih Semua Area
                  </a-checkbox>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="Penjual" prop="distributor" class="mb-2">
                  <a-select
                    v-model="form.distributor"
                    mode="multiple"
                    size="large"
                    class="select-antd-default"
                    placeholder="Cari & Pilih Penjual"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    :max-tag-count="5"
                    :disabled="distributorAll || isDetail"
                    allow-clear
                    @change="onChangeSelect('distributors')"
                    @search="searchDistributor"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" />
                    <a-select-option v-for="dist in filteredDistributor" :key="dist.id">
                      {{ dist.name }}
                      <span v-if="dist.address" class="dist-address">
                        {{ dist.address.city || '-' }}
                      </span>
                    </a-select-option>
                  </a-select>
                  <a-checkbox v-if="!isDetail" name="distributor" @change="onChangeAll">
                    Pilih Semua Penjual
                  </a-checkbox>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
        </div>
        <div v-if="form.customer_base === 2" style="margin-top: 30px">
          <div class="radio-title mt-3">
            Pelanggan
          </div>
          <div class="radio-subtitle mb-3">
            Tentukan pelanggan berdasarkan kategori dan pelanggan
          </div>
          <div class="target-promotion">
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-model-item label="Kategori Pelanggan" prop="category" class="mb-2">
                  <a-select
                    v-model="form.category"
                    mode="multiple"
                    size="large"
                    class="select-antd-default"
                    placeholder="Cari & Pilih Kategori Pelanggan"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    :max-tag-count="5"
                    :disabled="categoryAll || isDetail"
                    allow-clear
                    @change="onChangeSelect('categories')"
                    @search="searchCustomerCategory"
                  >
                    <a-select-option v-for="cat in filteredCategory" :key="cat.id">
                      {{ cat.category_name }}
                    </a-select-option>
                  </a-select>
                  <a-checkbox v-if="!isDetail" name="category" @change="onChangeAll">
                    Pilih Semua Kategori
                  </a-checkbox>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="Pelanggan" prop="customer" class="mb-2">
                  <a-select
                    v-model="form.customer"
                    mode="multiple"
                    size="large"
                    class="select-antd-default"
                    placeholder="Cari & Pilih Pelanggan"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    :max-tag-count="5"
                    :disabled="customerAll || isDetail"
                    allow-clear
                    @change="onChangeSelect('customers')"
                    @search="searchCustomer"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" />
                    <a-select-option v-for="cust in filteredCustomer" :key="cust.customer_id">
                      {{ cust.customer_name }}
                    </a-select-option>
                  </a-select>
                  <a-checkbox v-if="!isDetail" name="customer" @change="onChangeAll">
                    Pilih Semua Pelanggan
                  </a-checkbox>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-card>

      <!-- Pengaturan Promosi -->
      <a-card :title="$t('discount.setting_promotion')" class="w-100 mt-4" :bordered="false">
        <a-checkbox v-if="!isDetail" class="mb-4" @change="onChangeCombine">
          {{ $t('voucher.voucher_combined') }}
        </a-checkbox>
        <a-row :gutter="24">
          <a-col :span="20">
            <a-form-model-item :label="$t('discount.program_type')" prop="type">
              <a-select
                v-model="form.type"
                size="large"
                class="select-antd-default"
                :placeholder="$t('discount.select_program')"
                :options="programs"
                :disabled="isDetail || !isValid"
                allow-clear
                @change="onChangeType"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="form.type === 'product'" :gutter="24">
          <a-col :span="20">
            <a-form-model-item :label="$t('discount.product_type')" prop="typeProduct">
              <a-radio-group
                v-model="form.typeProduct"
                class="radio-product"
                :options="options"
                :disabled="isDetail || !isValid"
                @change="onChangeCategory"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="form.type === 'product'" :gutter="24">
          <a-col :span="20">
            <a-form-model-item
              v-if="form.typeProduct === 'product'"
              :label="$t('discount.product_item')"
              prop="product"
            >
              <a-select
                v-model="form.product"
                mode="multiple"
                size="large"
                class="select-antd-default"
                :placeholder="`Cari & ${$t('discount.select_item')}`"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                :disabled="isDetail || !isValid"
                allow-clear
                @change="fetchProduct('change', '')"
                @search="fetchProduct('search', $event)"
              >
                <a-spin v-if="fetching" slot="notFoundContent" />
                <a-select-opt-group v-for="prod in products" :key="prod.id">
                  <span slot="label">{{ prod.title }}</span>
                  <a-select-option v-for="catalog in prod.catalogs" :key="catalog.id">
                    {{ catalog.title }}
                    <span v-if="prod.catalogs && prod.catalogs.length">
                      {{ `- SKU: ${catalog.sku}` }}
                    </span>
                  </a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              v-else-if="form.typeProduct === 'category'"
              :label="$t('discount.product_category')"
              prop="kategori"
            >
              <a-select
                v-model="form.kategori"
                mode="multiple"
                size="large"
                class="select-antd-default"
                placeholder="Cari & Pilih Kategori Produk"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                :disabled="isDetail || !isValid"
                allow-clear
                @change="onChangeSelect('kategori')"
                @search="searchProductCategory"
              >
                <a-select-option v-for="kat in filteredKategori" :key="kat.id">
                  {{ kat.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              v-else
              :label="$t('discount.product_brand')"
              prop="brands"
            >
              <a-select
                v-model="form.brands"
                mode="multiple"
                size="large"
                class="select-antd-default"
                placeholder="Cari & Pilih Brand Produk"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                :disabled="isDetail || !isValid"
                allow-clear
                @change="onChangeSelect('brands')"
                @search="searchProductBrand"
              >
                <a-select-option v-for="brd in filteredBrand" :key="brd.id">
                  {{ brd.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('discount.promotion_quota')" prop="quota">
              <InputNumber
                v-model="form.quota"
                size="large"
                :placeholder="$t('discount.promotion_quota')"
                :disabled="isDetail || !isValid"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="20">
            <div class="promotion-table">
              <div class="d-flex align-items-center">
                <div class="promotion-title">
                  {{ $t('discount.promotion') }}
                </div>
                <a-button
                  v-if="!isDetail || !isValid"
                  type="primary"
                  class="ml-auto"
                  :disabled="!form.type || !isValid"
                  @click="onOpenModal"
                >
                  Tambah Promosi
                </a-button>
              </div>
              <a-table
                :columns="columns"
                :row-key="(record, index) => index"
                :data-source="promotionList"
                :pagination="false"
              >
                <template slot="min" slot-scope="text, record">
                  <div class="program-title">
                    {{ form.type === 'product' ? $t('discount.products') : $t('discount.transaction') }}
                  </div>
                  <div class="program-subtitle">
                    {{
                      form.type === 'product'
                        ? `${text} ${record.uom} - ${record.max} ${record.uom}`
                        : `Rp ${text},00 - Rp ${record.max},00`
                    }}
                  </div>
                </template>
                <template slot="max" slot-scope="text, record">
                  <div v-for="(gift, index) in record.gift" :key="index">
                    {{ $t(`discount.${gift.value_type}`) }}
                  </div>
                </template>
                <template slot="uom" slot-scope="text, record">
                  <div v-for="(gift, index) in record.gift" :key="index">
                    <span>{{ setTitle(gift) }}</span>
                    <span v-if="gift.value_type === 'percentage'" class="promo-subtitle">
                      {{ setSubtitle(gift) }}
                    </span>
                  </div>
                </template>
                <template slot="action" slot-scope="text, record, index">
                  <a-dropdown v-if="!isDetail" :overlay-style="overlayStyle">
                    <a @click.prevent="">
                      <a-icon type="more" />
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="1" class="py-2" @click="onOpenModal(index)">
                          <a-icon type="edit" />
                          Edit
                        </a-menu-item>
                        <a-menu-item key="2" class="py-2" @click="onDeletePromo(index)">
                          <a-icon type="delete" />
                          Hapus
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </template>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </a-card>

      <div v-if="!isDetail" class="discount-footer">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading"
          @click="goToBack"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          v-if="isPermitted && form.status !== 'ACTIVE'"
          type="primary"
          size="large"
          :loading="isLoading"
          @click="onSubmitDiscount"
        >
          {{ $route.query.voucher_id ? $t('utils.edit') : $t('utils.save') }}
        </a-button>
      </div>
    </a-form-model>

    <ModalPromotion
      :visible="visible"
      :program="form.type"
      :promo="initVal"
      :business-id="businessId"
      :list="products"
      @add="onAddProduct"
      @submit="onSubmitPromo"
      @close="onCloseModal"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getProductCategories } from '@/api/product'
import {
  getSeller,
  getDistributor,
  getCustomer,
  getCustomerCategory,
  getProductBrand,
  getProduct,
  addDiscount,
  getPromo,
  getCatalogName,
  cekVoucherName,
} from '@/api/channels/distributor'
import { getArea } from '@/api/data'
import { columnsCreateDiscount } from '@/data/columns'
import { rulesDiscount, rulesVoucher } from '@/data/rules'
import { useInputNumber } from '@/composable/useInputNumber'
import { useDateTime } from '@/composable/useDateTime'
import InputNumber from '@/components/Input/InputNumber.vue'

export default {
  name: 'CreateVoucher',
  components: {
    InputNumber,
    ModalPromotion: () => import('./modal/ModalPromotion.vue'),
  },
  beforeRouteLeave(to, from, next) {
    if (this.$route.query.voucher_id) delete this.$route.query.voucher_id
    if (this.$route.query.is_detail) delete this.$route.query.is_detail
    if (this.$route.query.is_duplicate) delete this.$route.query.is_duplicate
    next()
  },
  setup() {
    const { toNumber } = useInputNumber()
    const { parseISODate } = useDateTime()
    return { toNumber, parseISODate }
  },
  data() {
    this.fetchArea = debounce(this.fetchArea, 500)
    this.fetchDistributor = debounce(this.fetchDistributor, 500)
    this.fetchCustomer = debounce(this.fetchCustomer, 500)
    this.fetchProductCategory = debounce(this.fetchProductCategory, 500)
    this.fetchProductBrand = debounce(this.fetchProductBrand, 500)
    this.fetchProduct = debounce(this.fetchProduct, 500)
    this.fetchSeller = debounce(this.fetchSeller, 500)
    this.fetchVoucher = debounce(this.fetchVoucher, 500)
    return {
      sellerId: [],
      isValid: true,
      businessId: Object.keys(this.$store.getters['user/businessListObjectByKey'])[0],
      idPromo: -1,
      dateFormat: 'DD MMM YYYY HH:mm:ss',
      columns: columnsCreateDiscount,
      isLoading: false,
      fetching: false,
      endOpen: false,
      untilOpen: false,
      visible: false,
      areaAll: false,
      distributorAll: false,
      categoryAll: false,
      customerAll: false,
      promotionList: [],
      areas: [],
      filteredArea: [],
      distributors: [],
      filteredDistributor: [],
      categories: [],
      filteredCategory: [],
      customers: [],
      filteredCustomer: [],
      kategori: [],
      filteredKategori: [],
      brands: [],
      filteredBrand: [],
      products: [],
      tempProducts: [],
      programs: [
        { label: this.$t('discount.products'), value: 'product' },
        { label: this.$t('discount.transaction'), value: 'transaction' },
      ],
      options: [
        { label: this.$t('discount.product_item'), value: 'product' },
        { label: this.$t('discount.product_category'), value: 'category' },
        { label: this.$t('discount.product_brand'), value: 'brands' },
      ],
      initVal: {},
      rules: Object.assign({}, rulesDiscount, rulesVoucher),
      rulesSeller: {
        area: [{
          type: 'array',
          required: true,
          message: 'Area harus dipilih',
          trigger: 'change',
        }],
        distributor: [{
          type: 'array',
          required: true,
          message: 'Penjual harus dipilih',
          trigger: 'change',
        }],
      },
      rulesCustomer: {
        category: [{
          type: 'array',
          required: true,
          message: 'Kategori Pelanggan harus dipilih',
          trigger: 'change',
        }],
        customer: [{
          type: 'array',
          required: true,
          message: 'Pelanggan harus dipilih',
          trigger: 'change',
        }],
      },
      rulesProduct: {
        typeProduct: [{
          required: true,
          message: 'Tipe Produk harus dipilih',
          trigger: 'change',
        }],
        product: [{
          type: 'array',
          required: true,
          message: 'Item Produk harus dipilih',
          trigger: 'change',
        }],
        category: [{
          type: 'array',
          required: true,
          message: 'Kategori Produk harus dipilih',
          trigger: 'change',
        }],
        brands: [{
          type: 'array',
          required: true,
          message: 'Brand Produk harus dipilih',
          trigger: 'change',
        }],
      },
      overlayStyle: {
        width: '100px',
      },
      params: {
        page: 1,
        size: 1000,
      },
      form: {
        promotion_name: '',
        code: '',
        status: null,
        start_date: null,
        end_date: null,
        start_until: null,
        end_until: null,
        attribute: null,
        parent: 0,
        tier: 1,
        seller_base: 2,
        customer_base: 2,
        type: undefined,
        typeProduct: 'product',
        quota: '',
        area: [],
        distributor: [],
        category: [],
        customer: [],
        kategori: [],
        product: [],
        brands: [],
        is_combine: false,
      },
    }
  },
  computed: {
    business() {
      return Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
    },
    isDetail() {
      return !!this.$route.query.is_detail
    },
    isDuplicate() {
      return !!this.$route.query.is_duplicate
    },
    isPriciple() {
      return this.$store.state.user.restriction_base === 'Business'
    },
    isPermitted() {
      const permission = this.$store.getters['user/can']('voucher', 'reverse')
      return permission.includes('WRITE')
    },
    title() {
      return this.isDetail ? 'Detail Voucher'
        : !this.isDetail && this.$route.query.voucher_id ? 'Edit Voucher' : 'Tambah Voucher'
    },
  },
  watch: {
    '$route.query.warehouse_id'(newValue) {
      if (newValue) this.fetchSeller()
    },
    business(newValue) {
      if (newValue) {
        this.businessId = newValue
        this.init()
      }
    },
  },
  created() {
    this.fetchCustomerCategory()
    this.init()
  },
  methods: {
    init() {
      this.fetchArea()
      this.fetchDistributor()
      this.fetchCustomer()
      this.fetchProductCategory()
      this.fetchProductBrand()
      this.fetchProduct()
      if (this.$route.query.warehouse_id) this.fetchSeller()
      this.onChangeSeller(2)
      this.onChangeCustomer(2)
    },
    async fetchSeller() {
      const bodyValue = {
        businessId: this.businessId,
        params: {
          warehouse_id: this.$route.query.warehouse_id,
        },
      }
      await getSeller(bodyValue)
        .then(({ data: { data: response } }) => {
          this.sellerId = response.length ? response.map(item => item.seller_id) : []
        })
        .catch(err => {
          this.sellerId = []
          console.error(err)
        })
    },
    async fetchVoucher() {
      const bodyValue = {
        channel_id: this.$route.params.id,
        promo_id: this.$route.query.voucher_id,
        params: {
          business_id: this.businessId,
        },
      }
      await getPromo(bodyValue)
        .then(async ({ data: { data: response } }) => {
          let gifts = []
          const min = response.requirements.type === 'transaction' ? 'min_transaction' : 'min'
          const max = response.requirements.type === 'transaction' ? 'max_transaction' : 'max'
          const list = response.requirements.type === 'transaction'
            ? response.requirements.value
            : response.requirements.value.items

          this.form = {
            ...this.form,
            promotion_name: response.promotionName,
            code: response.code,
            status: response.status,
            start_date: this.$moment(response.startDate.replace(' ','')),
            end_date: this.$moment(response.endDate.replace(' ','')),
            start_until: this.$moment(response.startDate.replace(' ','')),
            end_until: response.requirements.type === 'transaction' && !!response.requirements?.value[0]?.gift[0]?.valid_until
              ? this.$moment(response.requirements.value[0].gift[0].valid_until)
              : response.requirements.type !== 'transaction' && !!response.requirements?.value?.items[0]?.gift[0]?.valid_until
              ? this.$moment(response.requirements.value.items[0].gift[0].valid_until)
              : '',
            attribute: response.attribute,
            parent: response.parent,
            tier: response.tier,
            seller_base: 2,
            area: response.sellerBase === 'area' ? response.sellerList.map(item => item.id) : [],
            distributor: response.sellerList.map(item => item.id) || [],
            customer_base: 2, // response.customerList[0].id === 'all' ? 1 : 2,
            category: [],
            customer: [],
            type: response.requirements.type,
            typeProduct: response.requirements.value.type,
            quota: new Intl.NumberFormat('id-ID').format(response.quota),
            kategori: response.requirements.value.type === 'category'
              ? response.requirements.value.list : [],
            product: response.requirements.value.type === 'product'
              ? response.requirements.value.list : [],
            brands: response.requirements.value.type === 'brands'
              ? response.requirements.value.list : [],
          }

          if(response.customerList.length) {
            this.form.category = response.customerList[0].id !== 'all' && response.customerBase === 'category'
              ? response.customerList.map(item => item.id)
              : response.customerList[0].id === 'all' && response.customerBase === 'category'
              ? ['all']
              : []
            this.form.customer = response.customerList[0].id !== 'all' && response.customerBase !== 'category'
              ? response.customerList.map(item => item.id)
              : response.customerList[0].id === 'all' && response.customerBase !== 'category'
              ? ['all']
              : []
          }

          this.promotionList = list.map(item => {
            return {
              min: new Intl.NumberFormat('id-ID').format(item[min]),
              max: new Intl.NumberFormat('id-ID').format(item[max]),
              uom: item.uom,
              gift: item.gift.map(element => {
                if(element.type === 'product') gifts.push(element.catalog_id)
                return {
                  type: 'voucher',
                  value_type: element.type === 'voucher'
                    ? element.value_type : element.type,
                  value: element.type === 'voucher' || element.type === 'coin'
                    ? new Intl.NumberFormat('id-ID').format(element.value) : '',
                  max_value: element.value_type && element.value_type === 'percentage'
                    ? new Intl.NumberFormat('id-ID').format(element.max_value)
                    : element.value_type && element.value_type !== 'percentage'
                    ? new Intl.NumberFormat('id-ID').format(element.value)
                    : '',
                  catalog_id: element.type === 'product'
                    ? element.catalog_id : '',
                  qty: element.type === 'product'
                    ? new Intl.NumberFormat('id-ID').format(element.qty) : '',
                  currency: 'Rp',
                  uom: element.uom,
                }
              }),
            }
          })

          if(response.requirements.value.type === 'product') {
            let catalogs = [].concat(response.requirements.value.list, gifts)
            response.requirements.value.list.forEach(catalog => {
              let length = this.products.length
              for(let i = 0; i < length; i++) {
                const index = this.products[i].catalogs.findIndex(element => element.id === catalog)
                if(index > -1) {
                  catalogs.splice(catalogs.indexOf(catalog), 1)
                  length = i + 1
                }
              }
            })

            if(catalogs.length) {
              const bodyReq = {
                id: this.$route.params.id,
                businessId: this.businessId,
                params: {
                  catalog_ids: catalogs.join(),
                },
              }
              await getCatalogName(bodyReq)
                .then(({ data: { data: resp } }) => {
                  let temp = []
                  let products = []
                  resp.forEach(res => {
                    if(gifts.length && gifts.includes(res.id)) {
                      for(let x = 0; x < this.promotionList.length; x++) {
                        const idx = this.promotionList[x].gift.findIndex(item => item.catalog_id == res.id)
                        if(idx > -1) {
                          this.promotionList[x].gift[idx].catalog_id = `${res.id}.${res.title}`
                        }
                      }
                    }

                    if(temp.length && temp.includes(res.product_id)) {
                      const ids = products.findIndex(item => item.id == res.product_id)
                      if(ids > -1) {
                        products[ids].catalogs.push({
                          id: res.id,
                          sku: res.sku,
                          title: res.title,
                        })
                      }
                    } else {
                      temp.push(res.product_id)
                      products.push({
                        id: res.product_id,
                        title: res.title.split('-')[0],
                        category: res.category_id,
                        catalogs: [{
                          id: res.id,
                          sku: res.sku,
                          title: res.title,
                        }],
                      })
                    }
                  })
                  if(products.length) {
                    this.products = this.products.concat(products)
                    this.tempProducts = this.tempProducts.concat(products)
                  }
                })
            }
          }
        })
        .catch(err => console.error(err))
    },
    async fetchArea() {
      const bodyValue = {
        business_id: this.businessId,
        params: {
          parentId: 228,
        },
      }
      await getArea(bodyValue)
        .then(({ data: { list } }) => {
          this.areas = list
        })
        .catch(err => {
          this.areas = []
          console.error(err)
        })
    },
    async fetchDistributor(value = '') {
      this.fetching = true
      const bodyValue = {
        headers: {
          'Business-Id': this.businessId,
        },
        params: {
          ...this.params,
          q: value,
          level: 'distributor',
          status: 'active',
        },
      }
      await getDistributor(bodyValue)
        .then(({ data }) => {
          const { records } = data.data
          this.distributors = records
          this.filteredDistributor = records
        })
        .catch(() => this.distributors = [])
        .then(() => this.fetching = false)
    },
    async fetchCustomerCategory() {
      await getCustomerCategory({ business_id: this.businessId })
        .then(({ data }) => {
          this.categories = data.content
          this.filteredCategory = data.content
        })
        .catch(() => this.categories = [])
    },
    async fetchCustomer(value = '') {
      this.fetching = true
      const bodyValue = {
        headers: {
          'Business-Id': this.businessId,
        },
        params: {
          ...this.params,
          q: value,
        },
      }
      await getCustomer(bodyValue)
        .then(({ data }) => {
          this.customers = data.data
          this.filteredCustomer = data.data
        })
        .catch(() => this.customers = [])
        .then(() => this.fetching = false)
    },
    async CheckVoucherName(value = '') {
      this.fetching = true
      const bodyValue = {
        headers: {
          'Business-Id': this.businessId,
        },
        params: {
          promotion_name: value,
          business_id: this.businessId,
        },
      }
      await cekVoucherName(bodyValue)
        .then(({ data }) => {
          if (data.message.toLowerCase() === 'fail') {
            this.isValid = false
          } else {
            this.isValid = true
          }
        })
        .catch((e) => console.log({e}))
        .then(() => this.fetching = false)
    },
    async fetchProductCategory() {
      await getProductCategories(this.businessId)
        .then(({ data }) => {
          this.kategori = data.data
          this.filteredKategori = data.data
        })
        .catch(() => this.kategori = [])
    },
    async fetchProductBrand() {
      const bodyValue = {
        business_id: this.businessId,
        id: this.$route.params.id,
      }
      await getProductBrand(bodyValue)
        .then(({ data }) => {
          this.brands = data.data
          this.filteredBrand = data.data
        })
        .catch(() => this.brands = [])
    },
    async fetchProduct(type = '', value = '') {
      this.fetching = true
      const bodyValue = {
        id: this.$route.params.id,
        businessId: this.businessId,
        params: {
          search_query: value ? value : '',
          page: 1,
          limit: 10,
          order: 'createdAt',
          sort: 'title',
          status: 'ACTIVE',
        },
      }
      await getProduct(bodyValue)
        .then(({ data }) => this.products = value === '' ? data.data.concat(this.tempProducts) : data.data)
        .catch(() => this.products = [])
        .finally(() => {
          if (!type && this.$route.query.voucher_id) {
            this.fetchVoucher()
          }
          this.fetching = false
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    searchDistributor(value) {
      this.filteredDistributor = this.distributors.filter(item => (
        item.name.toLowerCase().includes(value.toLowerCase())
      ))
    },
    searchCustomer(value) {
      this.filteredCustomer = this.customers.filter(item => (
        item.customer_name.toLowerCase().includes(value.toLowerCase())
      ))
    },
    searchCustomerCategory(value) {
      this.filteredCategory = this.categories.filter(item => (
        item.category_name.toLowerCase().includes(value.toLowerCase())
      ))
    },
    searchProductCategory(value) {
      this.filteredKategori = this.kategori.filter(item => (
        item.name.toLowerCase().includes(value.toLowerCase())
      ))
    },
    searchProductBrand(value) {
      this.filteredBrand = this.brands.filter(item => (
        item.title.toLowerCase().includes(value.toLowerCase())
      ))
    },
    onSubmitDiscount() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.isLoading = true
          const { voucher_id } = this.$route.query
          const method = voucher_id ? 'put' : 'post'
          let seller_list = []
          let customer_list = []
          let items = []
          let requirements = {}

          if (!this.promotionList.length) {
            this.$message.error('Daftar Promosi tidak boleh kosong')
            return
          }

          this.promotionList.forEach(promo => {
            let gift = []
            promo.gift.forEach(item => {
              if (item.value_type === 'fixed') {
                gift.push({
                  type: item.type,
                  value_type: item.value_type,
                  value: this.toNumber(item.max_value),
                  is_combine: this.form.is_combine,
                  valid_until: this.$moment(this.form.end_until)
                    .add((new Date().getTimezoneOffset() / -60), 'hours')
                    .toISOString(),
                })
              } else if (item.value_type === 'percentage') {
                gift.push({
                  type: item.type,
                  value_type: item.value_type,
                  value: this.toNumber(item.value),
                  max_value: this.toNumber(item.max_value),
                  is_combine: this.form.is_combine,
                  valid_until: this.$moment(this.form.end_until)
                    .add((new Date().getTimezoneOffset() / -60), 'hours')
                    .toISOString(),
                })
              } else if (item.value_type === 'coin') {
                gift.push({
                  type: item.value_type,
                  value_type: 'fixed',
                  value: this.toNumber(item.max_value),
                  is_combine: this.form.is_combine,
                  valid_until: this.$moment(this.form.end_until)
                    .add((new Date().getTimezoneOffset() / -60), 'hours')
                    .toISOString(),
                })
              } else {
                gift.push({
                  type: item.value_type,
                  catalog_id: item.catalog_id.split('.')[0],
                  qty: this.toNumber(item.qty),
                  uom: item.uom,
                  is_combine: this.form.is_combine,
                  valid_until: this.$moment(this.form.end_until)
                    .add((new Date().getTimezoneOffset() / -60), 'hours')
                    .toISOString(),
                })
              }
            })
            if (this.form.type === 'transaction') {
              items.push({
                min_transaction: this.toNumber(promo.min),
                max_transaction: this.toNumber(promo.max),
                gift,
              })
            } else {
              items.push({
                min: this.toNumber(promo.min),
                max: this.toNumber(promo.max),
                uom: promo.uom,
                gift,
              })
            }
          })

          if (this.form.seller_base === 1) {
            seller_list = this.distributors
            customer_list = [{ id: 'all' }]
          } else {
            if (this.form.distributor.length) {
              if (this.distributors.length === this.form.distributor.length) {
                seller_list = this.distributors
              } else {
                if (this.distributorAll) {
                  seller_list = this.distributors
                } else {
                  seller_list = this.form.distributor.map(item => ({ id: item }))
                }
              }
            } else {
              if (this.form.area.length) {
                if (this.areas.length === this.form.area.length) {
                  seller_list = this.areas.map(item => ({ id: item.value }))
                } else {
                  if (this.areaAll) {
                    seller_list = this.areas.map(item => ({ id: item.value }))
                  } else {
                    seller_list = this.form.area.map(item => ({ id: item }))
                  }
                }
              }
            }

            if (this.form.customer_base === 1) {
              customer_list = [{ id: 'all' }]
            } else {
              if (this.form.customer.length) {
                if (this.customers.length === this.form.customer.length) {
                  customer_list = [{ id: 'all' }]
                } else {
                  customer_list = this.form.customer.map(item => ({ id: item }))
                }
              } else {
                if (this.categoryAll || this.customerAll) {
                  customer_list = [{ id: 'all' }]
                } else {
                  if (this.categories.length === this.form.category.length) {
                    customer_list = [{ id: 'all' }]
                  } else {
                    customer_list = this.form.category.map(item => ({ id: item }))
                  }
                }
              }
            }
          }

          if (this.form.type === 'product') {
            requirements = {
              type: this.form.type,
              value: {
                type: this.form.typeProduct,
                list: this.form.typeProduct === 'product' && this.form.product.length ? this.form.product 
                  : this.form.typeProduct === 'category' && this.form.kategori.length ? this.form.kategori
                  : this.form.typeProduct === 'brands' && this.form.brands.length ? this.form.brands
                  : [],
                items,
              },
            }
          } else {
            requirements = {
              type: this.form.type,
              value: items,
            }
          }

          const formAdd = {
            method,
            business_id: this.businessId,
            id: this.$route.params.id,
            data: {
              business_id: this.businessId,
              promotion_name: this.form.promotion_name,
              status: this.form.status,
              type: 'VOUCHER',
              attribute: this.form.attribute,
              code: this.form.code,
              parent: this.form.parent,
              tier: this.form.tier,
              quota: this.toNumber(this.form.quota),
              start_date: this.$moment(this.form.start_date.toISOString()),
              end_date: this.$moment(this.form.end_date.toISOString()),
              // start_date: moment(this.form.start_date)
              //   .add((new Date().getTimezoneOffset() / -60), 'hours')
              //   .toISOString(),
              // end_date: moment(this.form.end_date)
              //   .add((new Date().getTimezoneOffset() / -60), 'hours')
              //   .toISOString(),
              seller_base: this.form.seller_base === 1 || this.form.seller_base === 2 && this.form.distributor.length
                ? 'direct' : 'area',
              seller_list: this.isPriciple
                ? seller_list.map(item => ({ id: item.id }))
                : this.sellerId.map(item => ({ id: item })),
              customer_base: this.form.customer_base === 2 && this.form.customer.length ? 'direct' : 'category',
              customer_list: customer_list.map(item => ({ id: item.id })),
              seller_ids: this.sellerId,
              requirements,
            },
          }

          if (voucher_id) formAdd.data.id = voucher_id
          
          addDiscount(formAdd)
            .then(() => {
              this.$notification.success({
                message: 'Berhasil',
                description: 'Voucher Berhasil dibuat',
              })
              this.goToBack()
            })
            .catch((err) => {
              console.error(err.response.status)
              this.$notification.error({
                message: 'Gagal',
                description: err.response.status == 403
                  ? 'Anda tidak memiliki Hak Akses untuk membuat Voucher'
                  : 'Voucher Gagal dibuat',
              })
            })
            .then(() => this.isLoading = false)
        }
      })
    },
    onAddProduct(value) {
      const index1 = this.products.findIndex(item => item.id == value.id)
      if(index1 > -1) {
        const idx1 = this.products[index1].catalogs.findIndex(item => item.id == value.id)
        if(idx1 < 0) {
          this.products[index1].catalogs.push(value.catalogs[0])
        }
      } else {
        this.products.push(value)
      }
      
      const index2 = this.tempProducts.findIndex(item => item.id == value.id)
      if(index2 < 0) {
        this.tempProducts.push(value)
      }
    },
    onSubmitPromo(value) {
      const list = JSON.parse(JSON.stringify(value))
      this.onCloseModal()
      if (this.idPromo > -1) {
        this.promotionList[this.idPromo] = list
      } else {
        this.promotionList.unshift(list)
      }
    },
    onDeletePromo(index) {
      this.promotionList.splice(index, 1)
    },
    onChangeTier(value) {
      this.form.parent = value - 1
    },
    onChangeSelect(type) {
      let result = ''
      let name = ''
      switch(type) {
        case 'categories':
          result = 'filteredCategory'
          name = 'customer'
          break
        case 'customers':
          result = 'filteredCustomer'
          name = 'category'
          break
        case 'kategori':
          result = 'filteredKategori'
          break
        case 'brands':
          result = 'filteredBrand'
          break
        case 'distributors':
          name = 'distributor'
        default:
          result = 'filteredDistributor'
          name = 'area'
      }
      if(name) this.$refs.ruleForm.clearValidate(name)
      this[result] = this[type]

      if (type === 'areas' || type === 'distributors') {
        if (this.form.distributor.length) {
          this.rules.distributor = this.rulesSeller.distributor
          if (this.rules.area) delete this.rules.area
        } else {
          this.rules.area = this.rulesSeller.area
          if (this.rules.distributor) delete this.rules.distributor
        }
      }

      if (type === 'categories' || type === 'customers') {
        if (this.form.customer.length) {
          this.rules.customer = this.rulesCustomer.customer
          if (this.rules.category) delete this.rules.category
        } else {
          this.rules.category = this.rulesCustomer.category
          if (this.rules.customer) delete this.rules.customer
        }
      }
    },
    onChangeAll(event) {
      const { name } = event.target
      this[`${name}All`] = !this[`${name}All`]
      this.form[name] = this[`${name}All`] ? ['all'] : []

      const target = name === 'area'
        ? 'distributor'
        : name === 'category'
        ? 'customer'
        : name === 'customer'
        ? 'category'
        : 'area'
      const mainRules = name === 'area' || name === 'distributor'
        ? 'rulesSeller'
        : 'rulesCustomer'
      this.removeRules(`${name}All`, target, mainRules)
      this.$refs.ruleForm.clearValidate(name)
      this.$refs.ruleForm.clearValidate(target)
    },
    removeRules(source, target, mainRules) {
      if(this[source] && this.rules[target]) {
        delete this.rules[target]
      } else if(!this[source] && !this.rules[target]) {
        this.rules[target] = this[mainRules][target]
      }
    },
    onChangeSeller(value) {
      this.form.area = []
      this.form.distributor = []
      if (value === 1) {
        this.form.customer_base = 1
        this.areaAll = false
        this.distributorAll = false
        this.onChangeCustomer(1)
        if (this.rules.area) delete this.rules.area
        if (this.rules.distributor) delete this.rules.distributor
      } else {
        this.rules.area = this.rulesSeller.area
        this.rules.distributor = this.rulesSeller.distributor
      }
    },
    onChangeCustomer(value) {
      this.form.category = []
      this.form.customer = []
      if (value === 1) {
        this.categoryAll = false
        this.customerAll = false
        if (this.rules.category) {
          delete this.rules.category
          delete this.rules.customer
        }
      } else {
        this.rules.category = this.rulesCustomer.category
        this.rules.customer = this.rulesCustomer.customer
      }
    },
    onChangeType(value) {
      this.form.typeProduct = 'product'
      this.form.kategori = []
      this.form.product = []
      this.promotionList = []
      if (value === 'transaction') {
        if (this.rules.product) {
          delete this.rules.typeProduct
          delete this.rules.product
        }
      } else {
        const { typeProduct, product } = this.rulesProduct
        this.rules.typeProduct = typeProduct
        this.rules.product = product
      }
    },
    onChangeCategory(event) {
      this.$refs.ruleForm.clearValidate('product')
      this.$refs.ruleForm.clearValidate('kategori')
      this.$refs.ruleForm.clearValidate('brands')
      if (event.target.value === 'category') {
        if (this.rules.product) {
          delete this.rules.product
        }
        if (this.rules.brands) {
          delete this.rules.brands
        }
        this.form.product = []
        this.rules.kategori = this.rulesProduct.category
      } else if (event.target.value === 'product') {
        if (this.rules.kategori) {
          delete this.rules.kategori
        }
        if (this.rules.brands) {
          delete this.rules.brands
        }
        this.form.kategori = []
        this.rules.product = this.rulesProduct.product
      } else {
        if (this.rules.product) {
          delete this.rules.product
        }
        if (this.rules.kategori) {
          delete this.rules.kategori
        }
        this.form.brands = []
        this.rules.brands = this.rulesProduct.brands
       }
    },
    onChangePeriod(event) {
      if (event.target.checked) {
        this.form.start_until = this.form.start_date
        this.form.end_until = this.form.end_date
      }
    },
    onChangeCombine(event) {
      this.form.is_combine = event.target.checked
    },
    onStartOpenChange(open) {
      if (!open) {
        this.form.start_until = this.form.start_date
        this.endOpen = true
      }
    },
    onEndOpenChange(open) {
      this.endOpen = open
    },
    onStartChange(open) {
      if (!open) this.untilOpen = true
    },
    onEndChange(open) {
      this.untilOpen = open
    },
    onOpenModal(index = -1) {
      this.idPromo = index
      this.initVal = index > -1
        ? JSON.parse(JSON.stringify(this.promotionList[index]))
        : {}
      this.visible = true
    },
    onCloseModal() {
      this.visible = false
    },
    disabledStartDate(startValue) {
      const endValue = this.form.end_date
      if (!startValue || !endValue) return startValue && startValue < this.$moment().startOf('day')
      return (startValue && startValue.valueOf() < this.$moment().startOf('day')) || (startValue.valueOf() > endValue.valueOf())
    },
    disabledEndDate(endValue) {
      const startValue = this.form.start_date
      if (!endValue || !startValue) return endValue && endValue < this.$moment().startOf('day')
      return (endValue && endValue.valueOf() < this.$moment().startOf('day')) || (startValue.valueOf() >= endValue.valueOf())
    },
    disabledStartUntil(startValue) {
      const endValue = this.form.end_until
      if (!startValue || !endValue) return startValue && startValue < this.$moment().startOf('day')
      return (startValue && startValue.valueOf() < this.$moment().startOf('day')) || (startValue.valueOf() > endValue.valueOf())
    },
    disabledEndUntil(endValue) {
      const startValue = this.form.start_until
      if (!endValue || !startValue) return endValue && endValue < this.$moment().startOf('day')
      return (endValue && endValue.valueOf() < this.$moment().startOf('day')) || (startValue.valueOf() >= endValue.valueOf())
    },
    setTitle(record) {
      const { value_type, value, max_value, qty, uom } = record
      return value_type === 'percentage' ? `${value}%`
        : value_type === 'coin' ? `${max_value} Koin`
        : value_type === 'fixed' ? `Rp ${max_value},00`
        : `${qty} ${uom}`
    },
    setSubtitle(record) {
      const { value_type, max_value, catalog_id } = record
      return value_type !== 'product' 
        ? `(Max. Rp ${max_value},00)` 
        : `(${catalog_id.split('.')[1]})`
    },
    goToHome() {
      if (this.$route.query.voucher_id) {
        delete this.$route.query.voucher_id
      }
      this.$router.push({
        path: '/dashboard',
        query: { ...this.$route.query },
      })
    },
    goToBack() {
      if (this.$route.query.voucher_id) {
        delete this.$route.query.voucher_id
      }
      this.$router.back({
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss">
@import '../discount.module.scss';
</style>
